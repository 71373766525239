import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Aquest és un bolet de gust dolç i d’olor a gerani. El capell de fins a 7 cm de diàmetre, és primer acampanat i amb un ample mamelló pla i obtús al centre. El color varia de blanc al principi i grisenc al centre del capell per tornar tot gris amb el temps. Les làmines primer ascendents, després planes, fràgils, blanques per passar a rosades per la caiguda de les espores. Aquestes són rosades en massa, el·lipsoidals, de 6-7 x 3,5-4,5 micres. El peu és més o manco cilíndric, llarg, llis, de color blanc on a la base trobam una petita volva formada per quatre lòbuls, de color blanquinós que es taca de marronenc o grisenc amb el temps.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      